import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import f2 from './Component/f1.png';
import f4 from './Component/f3.png';
import brand1 from './Component/f7.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4'>
        <span style={{ fontSize: '35px' }}>Financing</span> <br />
        <small className='justify'>CALL US OR STOP IN TODAY TO EXPLORE ALL OF OUR AVAILABLE FINANCING OPTIONS</small>
      </div>
      <section className='container'>
        <div class="row p-2" >
          
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
            paddingInline: '5% 10%'
          }}>
            <div >


              <div className='row d-flex justify-content-center'>
                <div class="col-md-12">
                  <div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
                    <div class="card-body-c align-self-center">
                      <div className='d-flex justify-content-center'> <img src={f4} alt="Los Angeles" class="d-block h-[300px]" />
                      </div>
                     
                     
                    </div>
                    <p class="lead py-2">
                      <a href="https://www.synchrony.com/mmc/S0166152202" target="_blank" rel="noreferrer" class="btn btn-danger btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Apply Now</a>
                    </p>
                  </div>
                </div>

              </div>
              <br></br>
            </div>

          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
            paddingInline: '5% 10%'
          }}>
            <div >


              <div className='row d-flex justify-content-center'>
                <div class="col-md-12">
                  <div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
                    <div class="card-body-c align-self-center">
                      <div className='d-flex justify-content-center'> <img src={f2} alt="Los Angeles" class="d-block h-[300px]" />
                      </div>
                     
                     
                    </div>
                    <p class="lead py-2">
                      <a href="https://retailservices.wellsfargo.com/pl/2246020242" target="_blank" rel="noreferrer" class="btn btn-danger btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Apply Now</a>
                    </p>
                  </div>
                </div>

              </div>
              <br></br>
            </div>

          </div>
        </div>
      </section >
      {/* <section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Our trusted lending partners</span>
				</div>
				<section className='pt-4'>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
			</section>

			</section> */}
      <div><Footer /> </div>
    </div>
  )
}

export default About
